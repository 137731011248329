import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  BlogCard,
  ButtonContainer,
  CardImageWrapper,
  Container,
  FeatureHero,
  H1,
  H3,
  HR,
  MenuButton,
  ResponsiveGrid,
  SmallHeader,
  Span,
  TitleSection,
} from "../components/styled/components"
import Img from "gatsby-image"

const Blog = ({ data }) => {
  const filter = blog => {
    return tag === "All"
      ? true
      : !!blog.node.tags && !!blog.node.tags.includes(tag)
  }

  const [tag, setTag] = React.useState("All")
  const [filteredBlogs, filterBlogs] = React.useState(
    data.allContentfulBlog.edges
  )

  React.useEffect(() => {
    filterBlogs(data.allContentfulBlog.edges.filter(filter))
  }, [tag])

  const getTagsForMenuBar = () => {
    let tmp = []
    data.allContentfulBlog.edges.forEach(blog => {
      if (!!blog.node.tags)
        blog.node.tags.forEach(tag => {
          if (tmp.indexOf(tag) === -1 && tmp.length < 6) tmp.push(tag)
        })
    })
    return tmp
  }

  const TagsForMenuBar = getTagsForMenuBar()

  return (
    <Layout>
      <SEO title="Blog" />
      <FeatureHero>
        <section>
          <SmallHeader>Blog</SmallHeader>
          <H1 center>
            Become healthy. <br /> Stay healthy.
          </H1>
        </section>
      </FeatureHero>
      <Container>
        <ButtonContainer>
          <MenuButton onClick={() => setTag("All")} active={tag === "All"}>
            All
          </MenuButton>
          {TagsForMenuBar.map(MenuTag => (
            <MenuButton
              onClick={() => setTag(MenuTag)}
              active={MenuTag === tag}
            >
              {MenuTag}
            </MenuButton>
          ))}
        </ButtonContainer>

        <HR mb={5} />
        <ResponsiveGrid columns="1fr 1fr" gap="100px" mb={5}>
          {filteredBlogs.slice(0, 2).map((blog, index) => (
              <BlogCard>
                <Link to={`/blog/${blog.node.slug}/`} key={index}>
                  <CardImageWrapper>
                    <Img fluid={blog.node.featureImage.fluid} />
                  </CardImageWrapper>
                </Link>
                <TitleSection>
                  <Span>{blog.node.publicData}</Span>
                  <Link to={`/blog/${blog.node.slug}/`} key={index}>
                    <H3 css={{ marginTop: "1rem !important" }}>
                      {blog.node.title}
                    </H3>
                  </Link>
                  <Span pt={`3`} fontSize={"12px"}>
                    {!!blog.node.tags &&
                      blog.node.tags.map((tag, index) =>
                        index === blog.node.tags.length - 1 ? tag : `${tag}, `
                      )}
                  </Span>
                </TitleSection>
              </BlogCard>
          ))}
        </ResponsiveGrid>
        <ResponsiveGrid columns="1fr 1fr 1fr" mb={5}>
          {filteredBlogs.slice(2).map((blog, index) => (
            <BlogCard>
              <Link to={`/blog/${blog.node.slug}/`} key={index}>
                <CardImageWrapper height="220px">
                  <Img fluid={blog.node.featureImage.fluid} />
                </CardImageWrapper>
              </Link>
              <TitleSection height="180px">
                <Span>{blog.node.publicData}</Span>
                <Link to={`/blog/${blog.node.slug}/`} key={index}>
                  <H3
                    css={{
                      marginTop: "1rem !important",
                      marginBottom: "0.5rem !important",
                      fontSize: "20px !important",
                    }}
                  >
                    {blog.node.title}
                  </H3>
                </Link>
                <Span pt={1} fontSize={"12px"}>
                  {!!blog.node.tags &&
                    blog.node.tags.map((tag, index) =>
                      index === blog.node.tags.length - 1 ? tag : `${tag}, `
                    )}
                </Span>
              </TitleSection>
            </BlogCard>
          ))}
        </ResponsiveGrid>
      </Container>
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query Blog {
    file(relativePath: { eq: "background.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allContentfulBlog(sort: { fields: publicData, order: DESC }) {
      edges {
        node {
          title
          slug
          featureImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          tags
          publicData(formatString: "MMMM Do, YYYY")
        }
      }
    }
  }
`
